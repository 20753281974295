<template>
  <div>
    <div 
      v-if="!registration && !resetRequest && !change && !resetCode"
    >
      <h3 class="title">
        Login
      </h3>
      <form
        ref="login"
        @submit.prevent="login"
      >
        <b-field
          label="Email"
          label-for="email"
        >
          <b-input
            id="email"
            key="login-email"
            v-model="forms.login.username"
            placeholder="Email / username"
            type="email"
            icon-left="account"
            autocomplete="username"
            name="email"
            required
            @keyup.native.enter="login"
          />
        </b-field>
        <b-field
          label="Password"
          label-for="current-password"
        >
          <b-input
            id="current-password"
            key="login-password"
            v-model="forms.login.password"
            placeholder="*******"
            type="password"
            icon-left="lock"
            autocomplete="current-password"
            name="current-password"
            required
            @keyup.native.enter="login"
          />
        </b-field>
        <b-field>
          <b-button
            type="is-success"
            @click="login"
          >
            Login
          </b-button>
        </b-field>
        <b-field>
          <b-button
            type="is-link"
            @click="registration = true"
          >
            Register
          </b-button>
        </b-field>
        <b-field>
          <b-button
            type="is-text"
            @click="resetRequest = true"
          >
            Forgot password?
          </b-button>
        </b-field>
      </form>
    </div>
    <div v-if="registration">
      <h3 class="title">
        Register
      </h3>
      <form
        ref="register"
        @submit.prevent="register"
      >
        <div>
          <b-field
            label="Email"
            label-for="email"
          >
            <b-input
              id="email"
              key="register-email"
              v-model="forms.register.email"
              placeholder="Email"
              type="email"
              icon-left="account"
              autocomplete="username"
              required
              @keyup.native.enter="register"
            />
          </b-field>
          <b-field
            label="Password"
            label-for="new-password"
          >
            <b-input
              id="new-password"
              key="register-password"
              v-model="forms.register.password"
              placeholder="*******"
              type="password"
              icon-left="lock"
              autocomplete="new-password"
              name="new-password"
              required
              @keyup.native.enter="register"
            />
          </b-field>
          <b-field
            label="Name"
            label-for="name"
          >
            <b-input
              id="name"
              key="register-name"
              v-model="forms.register.name"
              placeholder="Full name"
              type="text"
              icon-left="user"
              autocomplete="name"
              name="name"
              required
              @keyup.native.enter="register"
            />
          </b-field>
          <b-field>
            <b-button
              type="is-success"
              @click="register"
            >
              Register
            </b-button>
          </b-field>
          <b-field>
            <b-button
              type="is-success"
              @click="registration = false"
            >
              Back
            </b-button>
          </b-field>
        </div>
      </form>
    </div>
    <div v-if="resetRequest">
      <h3 class="title">
        Forgot password?
      </h3>
      <form
        ref="forgotPassword"
        @submit.prevent="forgotPassword"
      >
        <b-field label="Email">
          <b-input
            key="forgot-email"
            v-model="forms.reset.email"
            placeholder="Email / username"
            type="text"
            icon-left="account"
            required
            @keyup.native.enter="forgotPassword"
          />
        </b-field>
        <b-field>
          <b-button
            type="is-success"
            @click="forgotPassword"
          >
            Reset password
          </b-button>
        </b-field>
        <b-field>
          <b-button
            type="is-success"
            @click="forgotPassword = false"
          >
            Back
          </b-button>
        </b-field>
      </form>
    </div>
    <div v-if="resetCode">
      <h3 class="title">
        Reset password
      </h3>
      <form
        ref="resetPassword"
        @submit.prevent="resetPassword"
      >
        <input
          id="email"
          type="hidden"
          name="email"
          :value="resetUsername"
        >
        <b-field
          label="New Password"
          label-for="new-password"
        >
          <b-input
            id="new-password"
            key="reset-password"
            v-model="forms.change.password"
            placeholder="*******"
            type="password"
            icon-left="lock"
            autocomplete="new-password"
            name="new-password"
            password-reveal="true"
            @keyup.native.enter="resetPassword"
          />
        </b-field>
        <b-field>
          <b-button
            type="is-success"
            @click="resetPassword"
          >
            Change password
          </b-button>
        </b-field>
        <b-field>
          <b-button
            type="is-success"
            @click="resetCode = undefined"
          >
            Back
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
  data() {
    return {
      api: axios.create({
        baseURL: "/api/management/",
        timeout: 30000
      }),
      registration: false,
      resetRequest: false,
      change: false,
      resetCode: undefined,
      resetUsername: undefined,
      forms: {
        login: {
          username: "",
          password: "",
        },
        register: {
          email: "",
          password: "",
          name: "",
        },
        reset: {
          username: "",
        },
        change: {
          password: ""
        }
      },
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('forgot') && urlParams.has('user')) {
      this.resetCode = urlParams.get('forgot');
      this.resetUsername = urlParams.get('user');
    }
    if (urlParams.has('verified')) {
      this.$buefy.toast.open({
        message: 'Email address verified, you can now login',
        type: 'is-success',
        duration: 5000,
        position: 'is-top'
      })
    }
  },
  methods: {
    login: async function() {
      if (!this.$refs.login.checkValidity()) {
        this.$buefy.toast.open({
          message: 'Please fill all required fields',
          duration: 10000,
          type: 'is-warning',
        });
        return;
      }
      var data = qs.stringify({
        'username': this.forms.login.username,
        'password': this.forms.login.password
      });
      try {
        const response = await this.api.post('/login', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        localStorage.setItem('token', response.data.token);

        this.$buefy.toast.open({
          message: 'Successfully logged in!',
          type: 'is-success',
        });
        this.$emit('login', response.data.token);
      } catch (e) {
        console.log(e)
        this.$buefy.toast.open({
          message: 'Login failed!',
          duration: 10000,
          type: 'is-danger',
        });
      }
    },
    register: async function() {
      if (!this.$refs.register.checkValidity()) {
        this.$buefy.toast.open({
          message: 'Please fill all required fields',
          duration: 10000,
          type: 'is-warning',
        });
        return;
      }
      var data = qs.stringify({
        'email': this.forms.register.email,
        'password': this.forms.register.password,
        'name': this.forms.register.name
      });
      try {
        await this.api.post('/register', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        this.$buefy.toast.open({
          message: 'Successfully registered, check your email to activate your account!',
          duration: 5000,
          type: 'is-success',
        });
        this.registration = false;
        history.pushState({}, '', '/#management');
      } catch (e) {
        console.log(e)
        this.$buefy.toast.open({
          message: 'Registration failed!',
          duration: 10000,
          type: 'is-warning',
        });
      }
    },
    forgotPassword: async function () {
      if (!this.$refs.forgotPassword.checkValidity()) {
        this.$buefy.toast.open({
          message: 'Please fill all required fields',
          duration: 10000,
          type: 'is-warning',
        });
        return;
      }
      var data = qs.stringify({
        'email': this.forms.reset.email
      });
      try {
        await this.api.post('/forgotpassword', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        this.$buefy.toast.open({
          message: 'Password reset requested, check your email to reset your password!',
          type: 'is-success',
          duration: 5000,
        });
        this.resetRequest = false;
      } catch (e) {
        console.log(e)
        this.$buefy.toast.open({
          message: 'Error in resetting password!',
          type: 'is-warning',
          duration: 10000,
        });
      }
    },
    resetPassword: async function () {
      if (!this.$refs.resetPassword.checkValidity()) {
        this.$buefy.toast.open({
          message: 'Please fill all required fields',
          duration: 10000,
          type: 'is-warning',
        });
        return;
      }
      var data = qs.stringify({
        'email': this.resetUsername,
        'currentPassword': this.resetCode,
        'newPassword': this.forms.change.password,
      });
      try {
        await this.api.post('/resetpassword', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        this.$buefy.toast.open({
          message: 'Password reset, you can now login with your new password!',
          type: 'is-success',
        });
        this.change = false;
        this.resetCode = null;
        history.pushState({}, '', '/#management');
      } catch (e) {
        console.log(e)
        this.$buefy.toast.open({
          message: 'Error in resetting password!',
          type: 'is-warning',
          duration: 10000,
        });
      }
    }
  }
};
</script>